import './App.css';
import '../src/Components/Styles/media.css'
import React, { useEffect } from 'react';

import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Logout from './Components/Pages/Logout';
import ProtectedRoute from './Components/Security/Security';
import PersistedRoute from './Components/Pages/PersistedRoute';
import Cookies from "js-cookie";


// Lazy load components
const Login = lazy(() => import('./Components/Pages/Login'));
const Layout = lazy(() => import('./Components/Layout/Layout'));
const Dashboard = lazy(() => import('./Components/Pages/Dashboard'));
const UserAdd = lazy(() => import('./Components/Pages/UserAdd'));
const UserList = lazy(() => import('./Components/Pages/UserList'));
const MyProfile = lazy(() => import('./Components/Pages/MyProfile'));
const Customer = lazy(() => import('./Components/Pages/Customer'));
const Addcustomer = lazy(() => import('./Components/Pages/Addcustomer'));
const Forgotpass = lazy(() => import('./Components/Pages/Forgotpass'));
const Otp = lazy(() => import('./Components/Pages/Otp'))
const Resetpass = lazy(() => import('./Components/Pages/Resetpass'))
const VehicleRegister = lazy(() => import('./Components/Pages/VehicleRegister'));
const VehicleRegisterList = lazy(() => import('./Components/Pages/VehicleRegisterList'));
const VehicleModel = lazy(() => import('./Components/Pages/VehicleModel'));
const VehicleModelList = lazy(() => import('./Components/Pages/VehicleModelList'));
const VehicleColor = lazy(() => import('./Components/Pages/VehicleColor'));
const VehicleColorList = lazy(() => import('./Components/Pages/VehicleColorList'));
const Maintenancemode = lazy(() => import('./Components/Pages/Maintenancemode'));
const Maintenence = lazy(() => import('./Components/Pages/Maintenence'));
const NotFound = lazy(() => import('./Components/Pages/Not_found'));
const Addmaintenence = lazy(() => import('./Components/Pages/Addmaintenence'));
const Vehicleassign = lazy(() => import('./Components/Pages/Vehicleassign'));
const Viewvehicle = lazy(() => import('./Components/Pages/Viewvehicle'));


function AppContent() {
  const location = useLocation();
  function getPageTitle(path) {
    if (path.includes('/useredit/')) {
      return 'User Edit';
    }
    if (path.includes('/batteryedit')) {
      return 'Battery Edit';
    }
    if (path.includes('/dealeredit')) {
      return 'Dealer Edit';
    }
    if (path.includes('/editcustomer')) {
      return 'Edit Customer';
    }

    if (path.includes('/addcustomer')) {
      return 'Add Customer';
    }



    if (path.includes('/vehicleassign')) {
      return ' Vehicle Assign';
    }


  



    switch (path) {
      case '/dashboard':
        return 'Dashboard';

      // user
      case '/useradd':
        return 'User Add';
      case '/userlist':
        return 'User List';
      case '/useredit/:id':
        return 'User Edit';

      case '/customermanagement':
        return 'Customer List';
      case '/addcustomer':
        return 'Custmer Add';
      case '/station':
        return 'Station List';
      case '/editcustomer':
        return 'Edit Customer';


    
      // myprofile
      case '/myprofile':
        return 'My Profile';

      // battery
     

      // vehicle register
      case '/vehicleregister':
        return 'Vehicle Register';
      case '/vehicleregisterlist':
        return 'Vehicle Register list'

      // vehicle model
      case '/vehiclemodel':
        return 'Vehicle Model';
      case '/vehiclemodellist':
        return 'Vehicle Model List'

      // vehicle color
      case '/vehiclecolor':
        return 'Vehicle Color'
      case '/vehiclecolorlist':
        return 'Vehicle Color List'

  
    
      default:
        return 'Users';
    }
  };




  return (
    <Suspense fallback={<div className="loader">Loading...</div>}>
      <Routes>
        <Route path="/" element={<Login />} />
        {/*forgatepass*/}
        <Route path="forgotpass" element={<Forgotpass />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/resetpass" element={<Resetpass />} />
        <Route path="Maintenancemode" element={<Maintenancemode />} />
        {/* NotFound */}
        <Route path="*" element={<NotFound />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<Layout pageTitle={getPageTitle(location.pathname)} />}>

            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="/dashboard" element={<PersistedRoute element={<Dashboard />} />} />
            {/* user */}
            <Route path="/useradd" element={<PersistedRoute element={<UserAdd />} />} />
            <Route path="/useredit/:id" element={<PersistedRoute element={<UserAdd />} />} />
            <Route path="/userlist" element={<PersistedRoute element={<UserList />} />} />
           
            <Route path="/myprofile" element={<PersistedRoute element={<MyProfile />} />} />
            {/* battery */}
            
            <Route path='/logout' element={<PersistedRoute element={<Logout />} />} />
            <Route path='/customermanagement' element={<PersistedRoute element={<Customer />} />} />
            <Route path="/addcustomer" element={<PersistedRoute element={<Addcustomer />} />} />
            <Route path="/editcustomer/:id" element={<PersistedRoute element={<Addcustomer />} />} />

            
            {/* vehicle Register */}
            <Route path='/vehicleregister' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregisteredit/:id' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregisteredit' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregisterlist' element={<PersistedRoute element={<VehicleRegisterList />} />} />

            {/* Vehicle Model */}
            <Route path='/vehiclemodel' element={<PersistedRoute element={<VehicleModel />} />} />
            <Route path='/vehiclemodeledit/:id' element={<PersistedRoute element={<VehicleModel />} />} />
            <Route path='/vehiclemodellist' element={<PersistedRoute element={<VehicleModelList />} />} />
            <Route path='/Viewvehicle' element={<PersistedRoute element={<Viewvehicle />} />} />

            {/* Vehicle Color */}
            <Route path='/vehiclecoloredit/:id' element={<PersistedRoute element={<VehicleColor />} />} />
            <Route path='/vehiclecolor' element={<PersistedRoute element={<VehicleColor />} />} />
            <Route path='/vehiclecolorlist' element={<PersistedRoute element={<VehicleColorList />} />} />
            {/* maintenence */}
            <Route path='/maintenence' element={<PersistedRoute element={<Maintenence />} />} />
            <Route path='/Addmaintenence' element={<PersistedRoute element={<Addmaintenence />} />} />
            <Route path='/Editmaintenence/:id' element={<PersistedRoute element={<Addmaintenence />} />} />
            {/* batteries */}
            <Route path='/vehicleassign/:CustomerID' element={<PersistedRoute element={<Vehicleassign />} />} />
            <Route path='/vehicleassign/' element={<PersistedRoute element={<Vehicleassign />} />} />


          </Route>
        </Route>
      </Routes>


    </Suspense>
  );
}

function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </div>
  );
}

export default App;